import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import api from "../api"; // Assuming you have an api utility for making requests

function PumpControl({ pumpName, pumpNumber, addCommandToQueue }) {
    const [speed, setSpeed] = useState(0);
    const [direction, setDirection] = useState(0);
    const [error, setError] = useState(null);

    // Load saved values from local storage on component mount
    useEffect(() => {
        const savedSpeed = localStorage.getItem(`pump${pumpNumber}Speed`);
        const savedDirection = localStorage.getItem(`pump${pumpNumber}Direction`);
        
        if (savedSpeed !== null) setSpeed(parseFloat(savedSpeed));
        if (savedDirection !== null) setDirection(parseInt(savedDirection, 10));
    }, [pumpNumber]);

    // Function to send the pump control command
    const debouncedSendCommand = useMemo(() => 
        debounce(async (pumpSpeed, pumpDirection) => {
            addCommandToQueue( async () => {
                const command = {
                    pump_number: pumpNumber,
                    direction: pumpDirection,
                    speed: parseInt(pumpSpeed),
                }
                console.log(`Sending control command for ${pumpName} with speed ${pumpSpeed} and direction ${pumpDirection}`);
                try {
                    await api.post(`/pump/control_pump/`, command);
                    setError(null); // Clear any previous errors if successful
                } catch (err) {
                    console.error(`Failed to send command for ${pumpName}:${err.response.data.detail}`);
                    setError(`${err.response.data.detail}`);
                }
            });
        }, 100),
        [pumpNumber, addCommandToQueue, pumpName, setError]
    );

    const handleSpeedChange = (event) => {
        const newSpeed = event.target.value;    
        setSpeed(newSpeed);
        localStorage.setItem(`pump${pumpNumber}Speed`, newSpeed); // Save speed to local storage
        debouncedSendCommand(Math.round(newSpeed * 100), direction);  // Call the debounced function
    };

    const handleDirectionChange = () => {
        const newDirection = direction === 0 ? 1 : 0;
        setDirection(newDirection);
        localStorage.setItem(`pump${pumpNumber}Direction`, newDirection); // Save direction to local storage
        debouncedSendCommand(Math.round(speed * 100), newDirection);  // Send new direction command
    };

    return (
        <div className="pump-control">
            <h6>{pumpName}</h6>
            {/* Speed Slider and Direction Switch in a Row */}
            <div className="row align-items-center">
                {/* Speed Control (3/4 of the row) */}
                <div className="col-9">
                    {/* <label htmlFor={`pump${pumpNumber}Speed`} className="form-label">SPEED</label> */}
                    {/* Flex container for the slider and speed value */}
                    <div className="d-flex align-items-center">
                        <input
                            type="range"
                            className="form-range range"
                            id={`pump${pumpNumber}Speed`}
                            min="0.00"
                            max="40.00"
                            step="0.01"
                            value={speed}
                            onChange={handleSpeedChange}
                            style={{ marginRight:'10px'}} // Add some space between the slider and value
                        />
                        {/* Display speed value inline */}
                        <span>{parseFloat(speed).toFixed(2)}%</span>
                    </div>
                </div>

                {/* Direction Control (1/4 of the row) */}
                <div className="col-3">
                    {/*<label htmlFor={`pump${pumpNumber}DirectionSwitch`} className="form-label">DIRECTION</label> */}
                    <div className="d-flex align-items-center justify-content-center">
                        <span className="me-2">CW</span>
                        <div className="form-check form-switch aria-label">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id={`pump${pumpNumber}DirectionSwitch`}
                                checked={direction === 1}
                                onChange={(e) => {
                                    handleDirectionChange();  // Your direction change handler
                                    e.target.blur();          // Remove focus after the click
                                }}
                                style={{ minWidth: '100px', minHeight: '45px'}}
                            />
                        </div>
                        <span className="ms-2">CCW</span>
                    </div>
                </div>
            </div>
            <p className="text-danger" style={{ height: '2.0em', fontSize:'10px'}}>
                {error ? error : ''}
            </p>
        </div>
    );
}

export default React.memo(PumpControl);
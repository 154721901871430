/**
 * @file ScreenSaver.js
 * @brief ScreenSaver class to handle user inactivity and navigate to a screensaver.
 */

/**
 * @class ScreenSaver
 * @brief Class to handle user inactivity and navigate to a screensaver.
 */
class ScreenSaver {
  /**
   * @brief Constructor for the ScreenSaver class.
   */
  constructor() {
    this.navigate = null; // Will be set in the `initialize` method
    this.inactivityTimeout = null;
    this.INACTIVITY_TIME_LIMIT = 60000; // 60 seconds for testing
    this.originalPath = "/"; // Default is null to ensure it's dynamically set
    this.isScreenSaverActive = false;
    this.isInitialized = false;
    this.screenSaverEnabled = true;
  }

  /**
   * @brief Initialize the ScreenSaver with the navigate function.
   * @param {function} navigate - Function to navigate to different routes.
   */
  initialize(navigate) {
    if (this.isInitialized) return; 
    this.isInitialized = true;
    this.navigate = navigate;
    this.resetInactivityTimer(); // Start the timer
    this.setupEventListeners(); // Set up event listeners for user activity
  }

  /**
   * @brief Set up event listeners for user activity.
   */
  setupEventListeners() {
    const events = ['mousemove', 'mousedown', 'keypress', 'touchstart', 'scroll'];
    events.forEach((event) => {
      window.addEventListener(event, () => this.handleUserActivity());
    });
  }

  setOriginalPath(path) {
    if (!this.isScreenSaverActive) {
        this.originalPath = path;
    }
  }

  /**
   * @brief Handle user activity by resetting the inactivity timer and returning to original path.
   */
  handleUserActivity() {
    if(!this.screenSaverEnabled) {
      return;
    }
    this.resetInactivityTimer();
    if (this.isScreenSaverActive) {
      this.navigate(this.originalPath || '/'); // Navigate back to saved originalPath or fallback to '/'
      this.isScreenSaverActive = false;
    }
  }

  /**
   * @brief Reset the inactivity timer.
   */
  resetInactivityTimer() {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
    this.inactivityTimeout = setTimeout(() => {
      this.activateScreenSaver();
    }, this.INACTIVITY_TIME_LIMIT);
  }

  /**
   * @brief Activate the screensaver by navigating to the screensaver path.
   */
  activateScreenSaver() {
    if(!this.screenSaverEnabled) {
      return;
    }
    this.isScreenSaverActive = true;
    this.navigate('/screensaver'); // Navigate to the screensaver path
  }
}

const screenSaverInstance = new ScreenSaver();
export default screenSaverInstance;
import './Select5.css';
import { useState, useEffect } from 'react';
import screenSaverInstance from '../screensaver/ScreenSaver';
import { useLocation } from 'react-router-dom';

function Select5() {

  console.log("Process.env:", process.env.REACT_APP_API_BASE_URL); // Debug: Check API Base URL
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';
  const [bgImage, setBgImage] = useState('');
  const [optionImages, setOptionImages] = useState([]);
  const [activeOption, setActiveOption] = useState(null); // Track active button
  const [isExecuting, setIsExecuting] = useState(false); // New loading state
  const location = useLocation();

  useEffect(() => {
    screenSaverInstance.handleUserActivity(); // Inform ScreenSaver about user activity

    // Fetch images data from JSON file 
    fetch('/assets/guis/option_5_gui.json')
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched JSON Data:", data);  // Log fetched JSON data
        setBgImage(data.background);
        setOptionImages(data.options);
      })
      .catch((error) => console.error("Error loading images:", error));
  }, []);

  useEffect(() => { 
    // Update original path 
    screenSaverInstance.setOriginalPath(location.pathname);
  }, [location.pathname]);

  // Handle touch of the option
  const handleOptionTouched = async (optionNumber) => {
    console.log("Option clicked:", optionNumber); // 

    screenSaverInstance.handleUserActivity(); // Inform ScreenSaver about user activity

    // Prevent further clicks while executing
    if (isExecuting) {
      console.log("Another command is in progress. Please wait.");
      return; 
    }

    // Retrieve the selected button's details
    const selectedOption = optionImages.find((opt) => opt.option === optionNumber.toString());

    try {
      console.log(`Selected Drink: ${selectedOption.drink}, 
        Volume: ${selectedOption.volume},
        MaxTime: ${selectedOption.max_time}`); // Debug: Check selected option

      // Start executing the drink
      setActiveOption(Number(optionNumber));
      setIsExecuting(true); // Set loading to true

      const response = await fetch(`${apiBaseUrl}/command_executor/execute_drink`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          drink: selectedOption.drink,
          volume: selectedOption.volume,
          max_time: selectedOption.max_time
        })
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Command executed successfully:", result.message);
      } else {
        console.error("Command execution failed:", result.message);
      }
    } catch (error) {
      console.error("Error sending drink data:", error);
    } finally {
      setIsExecuting(false); // Set loading to false
      setActiveOption(null); // Reset active
    }
  };


  // Handle Stop Button Touch
  const handleStopTouch = async () => {
    console.log("Stopping execution...");
  
    screenSaverInstance.handleUserActivity(); // Inform ScreenSaver about user activity

    try {
      const response = await fetch(`${apiBaseUrl}/command_executor/jump-to-footer/`, {
        method: 'POST'
      });
  
      const result = await response.json();
      if (response.ok) {
        console.log("STOP BUTTON:", result.status); // Should log "Jump to footer triggered"
        setIsExecuting(false);
      } else {
        console.error("Failed to trigger jump to footer:", result);
      }
    } catch (error) {
      console.error("Error sending stop command:", error);
    }
  };

  const getTransformStyle = (optionIndex) => {
    const baseTransforms = [
      "rotate(-126deg) translate(420px) rotate(126deg)",
      "rotate(-54deg) translate(420px) rotate(54deg)",
      "rotate(172deg) translate(400px) rotate(-172deg)",
      "rotate(8deg) translate(400px) rotate(-8deg)",
      "rotate(90deg) translate(320px) rotate(-90deg)",
    ];
    
    const baseTransform = baseTransforms[optionIndex];
    const isActive = Number(activeOption) === optionIndex  + 1;

    // Apply scaling only if the button is active
    const scale = isActive ? 'scale(1.2)' : 'scale(1)';

    console.log(`Button ${optionIndex + 1} transform:`, `${baseTransform} ${scale}`); // Debug: Check transform

    const transform = `${baseTransform} ${scale}`;

    return transform;
  };

  return (
    <div
      className="Select5"
      style={{
        backgroundImage: `url(${bgImage})`
      }}
    >
      <div className='options-container'>
        {optionImages.map((opt, index) => (
          <img
            key={opt.option}
            src={opt.image}
            alt={`Option ${opt.option}`}
            className={`option button-${index + 1}`}
            style={{ 
              transform: getTransformStyle(index),
              opacity: activeOption === null || Number(activeOption) === index + 1 ? 1 : 0.5, // Dim non-active buttons
              transition: "transform 0.3s ease, opacity 0.3s ease",
            }}
            onMouseDown={() => handleOptionTouched(opt.option)} // For mouse down
            onTouchStart={() => handleOptionTouched(opt.option)} // For touch on mobile
          />
        ))}
      </div>
      {/* Display Stop Button in Center if Executing */}
      {isExecuting && (
        <div className="stop-button-overlay" onTouchStart={() => handleStopTouch()} onClick={() => handleStopTouch()} >
          <img src="/assets/images/stop_button.png" alt="Stop Button" />
        </div>
      )}
    </div>
  );
}

export default Select5;

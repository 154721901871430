import React, { useState, useEffect } from "react";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale  } from 'chart.js';
import 'chartjs-adapter-date-fns'; // Adapter for time scale

// Register the required components for Chart.js
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale 
);

function FlowMeterChart({ latestFlowData, readPeriod }) {
    const [periodData, setPeriodData] = useState([]);               // To store period data
    const [temperatureData, setTemperatureData] = useState([]);     // To store temperature data
    const [flowData, setFlowData] = useState([]);                   // To store flow data
    const [volumeData, setVolumeData] = useState([]);               // To store volume data    
    const [timeStamps, setTimeStamps] = useState([]);               // To store timestamps
    const maxDataPoints = 50;

    // Update chart data with new flow meter data
    useEffect(() => {
        if (latestFlowData) {
            setPeriodData(prevData => {
                const updatedData = [...prevData, latestFlowData.period];
                return updatedData.length > maxDataPoints ? updatedData.slice(-maxDataPoints) : updatedData;
            });

            setTemperatureData(prevData => {
                const updatedData = [...prevData, latestFlowData.temperature];
                return updatedData.length > maxDataPoints ? updatedData.slice(-maxDataPoints) : updatedData;
            });

            setFlowData(prevData => {
                const updatedData = [...prevData, latestFlowData.flow];
                return updatedData.length > maxDataPoints ? updatedData.slice(-maxDataPoints) : updatedData;
            });

            setVolumeData(prevData => {
                const updatedData = [...prevData, latestFlowData.volume];
                return updatedData.length > maxDataPoints ? updatedData.slice(-maxDataPoints) : updatedData;
            });

            setTimeStamps(prevTime => {
                const updatedTime = [...prevTime, latestFlowData.timestamp];
                return updatedTime.length > maxDataPoints ? updatedTime.slice(-maxDataPoints) : updatedTime;
            });
        }
    }, [latestFlowData]);

    const tickInterval = readPeriod / 1000; // Convert milliseconds to seconds for the chart

    const chartOptions = {
        scales: {
            x: {
                type: 'linear',
                time: {
                    unit: 'second',
                    stepsize: tickInterval,
                },
                ticks: {
                    autoSkip: false,
                    maxTicksLimit: Math.floor(120 / tickInterval),
                },
                title: {
                    display: true,
                    text: 'Time (s)',
                },
            },
            y: {
                title: {
                    display: true,
                },
            },
        },
        animation: false,
    };

    return (
        <div>
            <h5>Flow Meter Data</h5>
            <div className="chart-row">
                <div className="chart-container">
                    <h6>Flowmeter Period</h6>
                    <Line data={{
                        labels: timeStamps,
                        datasets: [{
                            label: "Period [ms]",
                            data: periodData,
                            backgroundColor: "rgba(75,192,192,1)",
                            borderColor: "rgba(75,192,192,0.2)",
                            fill: false,
                            tension: 0.1,
                        }],
                    }} options={{ ...chartOptions, scales: { ...chartOptions.scales, y: { title: { text: 'Period [ms]' } } } }} />
                </div>

                <div className="chart-container">
                    <h6>Flowmeter Flow</h6>
                    <Line data={{
                        labels: timeStamps,
                        datasets: [{
                            label: "Flow [ml/s]",
                            data: flowData,
                            backgroundColor: "rgba(75,192,192,1)",
                            borderColor: "rgba(75,192,192,0.2)",
                            fill: false,
                            tension: 0.1,
                        }],
                    }} options={{ ...chartOptions, scales: { ...chartOptions.scales, y: { title: { text: 'Flow [ml/s]' } } } }} />
                </div>

                <div className="chart-container">
                    <h6>Flowmeter Volume in ml</h6>
                    <Line data={{
                        labels: timeStamps,
                        datasets: [{
                            label: "Volume",
                            data: volumeData,
                            backgroundColor: "rgba(153,102,255,1)",
                            borderColor: "rgba(153,102,255,0.2)",
                            fill: false,
                            tension: 0.1,
                        }],
                    }} options={{ ...chartOptions, scales: { ...chartOptions.scales, y: { title: { text: 'Volume' } } } }} />
                </div>

                <div className="chart-container">
                    <h6>Flowmeter Temperature</h6>
                    <Line data={{
                        labels: timeStamps,
                        datasets: [{
                            label: "Temperature [°C]",
                            data: temperatureData,
                            backgroundColor: "rgba(255,99,132,1)",
                            borderColor: "rgba(255,99,132,0.2)",
                            fill: false,
                            tension: 0.1,
                        }],
                    }} options={{ ...chartOptions, scales: { ...chartOptions.scales, y: { title: { text: 'Temperature [°C]' } } } }} />
                </div>
            </div>
        </div>
    );
}

export default FlowMeterChart;

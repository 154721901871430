import React, { useState, useCallback, useEffect } from 'react';
import FlowMeterChart from './FlowMeterChart';
import api from '../api'; // Assuming this is your api handler

function FlowMeterControl({ flowmeterName, flowmeterNumber, addCommandToQueue }) {
    const [readPeriod, setReadPeriod] = useState(1000); // Default read period in ms
    const [isReading, setIsReading] = useState(false); // To track if reading is active
    const [error, setError] = useState(null);
    const [latestFlowData, setLatestFlowData] = useState(null); // Store only the latest data point
    const [startTime, setStartTime] = useState(0); // Capture start time
    const [producer, setProducer] = useState("unknown"); // Store the producer value
    const [nozzleSize, setNozzleSize] = useState("unknown"); // Store the nozzle size value
    const [k, setK] = useState(0); // Store the flowmeter k factor
    const [period, setPeriod] = useState(0); // Store the flow period value
    const [flow,setFlow] = useState(0); // Store the flow value in mL/s
    const [counts, setCounts] = useState(0); // Store the counts value
    const [volume, setVolume] = useState(0); // Store the volume value
    const [temperature, setTemperature] = useState(0); // Store the temperature value   


    // Function to send the reading command
    const readFlowMeter = useCallback(() => {
        addCommandToQueue(async () => {
            const command = { flowmeter_number: flowmeterNumber };
    
            try {
                const response = await api.post(`/flowmeter/read_data/`, command);
                const res = response.data.responses;
    
                if (res.error_code === -2) {
                    //console.log("No new data to show.");
                    setProducer(res.producer);
                    setNozzleSize(res.nozzle_size);                    
                    setK(res.k);
                    setPeriod(0);
                    setFlow(0);
                    setCounts(0);
                    setVolume(0);
                    setTemperature(0);
                } else {
                    setProducer(res.producer);
                    setNozzleSize(res.nozzle_size);
                    setK(res.k);
                    setPeriod(res.period);
                    setCounts(res.counts);
                    setFlow(res.flow);          // Flow in ml/s
                    setVolume(res.volume);      // Volume in ml
                    setTemperature(res.temperature);

                    console.log(`Producer: ${producer}, Nozzle Size: ${nozzleSize}, k: ${k},  
                        Period: ${period}, Counts: ${counts}, Temperature: ${temperature}, Flow: ${flow}, Volume: ${volume}`);
                }
    
                setError(null); // Clear any previous errors if successful
            } catch (err) {
                console.error(`Failed to send command for flowmeter: ${err.response.data.detail}`);
                setError(`${err.response.data.detail}`);
            }
    
            // Update latestFlowData outside the try block
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            setLatestFlowData({ producer, nozzleSize, period, counts, temperature, flow, volume, k, timestamp: elapsedTime });
        });
    }, [startTime, flowmeterNumber, producer, nozzleSize, period, counts, temperature, flow, volume, k, addCommandToQueue]);

    // Handle starting and stopping the periodic reading process
    useEffect(() => {
        let intervalId;
        if (isReading) {
            intervalId = setInterval(() => {
                readFlowMeter();
            }, readPeriod);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isReading, readPeriod, readFlowMeter]);

    // Function to handle Start/Stop button click
    const handleStartStopReading = () => {
        setIsReading(prevReading => {
            const newReadingState = !prevReading; 
            if(newReadingState){
                setStartTime(Date.now());   // Capture the time when readings start
            }
            return newReadingState;
        });
    };

    // Function to handle slider change
    const handlePeriodChange = (event) => {
        const newPeriod = event.target.value;
        setReadPeriod(newPeriod);
    };

    return (
        <div className="flow-meter-control border border-2 rounded p-3">
            <h5 className='mb-3'>Flow Meter Control - {flowmeterName}, producer: {producer}, nozzle size: {nozzleSize}</h5>
            <div className='row'>
                <div className="col-10">
                    <div className="period-slider row mb-3">
                        <div className="col-2">
                            <label htmlFor="readPeriodSlider" className="form-label">
                                Read Period (ms)
                            </label>
                        </div>
                        <div className="col-10">
                            <div className="d-flex align-items-center">
                                <input
                                    type="range"
                                    className="form-range range"
                                    id="readPeriodSlider"
                                    min="50"
                                    max="5000"
                                    step="50"
                                    value={readPeriod}
                                    onChange={handlePeriodChange}
                                    style={{ marginRight:'20px'}}
                                />
                                {/* Display readPeriod value inline */}
                                <span >{readPeriod}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">     {/*  d-flex align-items-center justify-content-center*/}
                    <button
                        className={`btn ${isReading ? 'btn-danger' : 'btn-primary'}`}
                        onClick={handleStartStopReading}
                    >
                        {isReading ? 'Stop Reading' : 'Start Reading'}
                    </button>
                </div>
            </div>
            {error && <p className="text-danger">{error}</p>}
            <FlowMeterChart latestFlowData={latestFlowData} readPeriod={readPeriod}/> {/* Pass latest data */}
        </div>
    );
}

export default FlowMeterControl;

import React, { useState, useCallback, useEffect } from 'react';
import api from '../api'; // Assuming this is your api handler

function PumpStatus({ pumpName, pumpNumber, addCommandToQueue }) {
    const [updatePeriod, setUpdatePeriod] = useState(1000); // Default read period in ms
    const [isUpdating, setIsUpdating] = useState(false); // To track if reading is active
    const [error, setError] = useState(null);
    const [startTime, setStartTime] = useState(0); // Capture start time
    const [pumpStatus, setPumpStatus] = useState(null); 
    
    // Function to send the reading command
    const readPumpStatus = useCallback(() => {
        addCommandToQueue(async () => {
            const command = {
                pump_number: pumpNumber,
            }
            console.log(`Sending status request for ${pumpName}`);
            try {
                const response = await api.post(`/pump/pump_status/`, command);

                const res = response.data.responses;

                if (res === null) {
                    console.log(`No new data to show.`);

                } else if (res){
                    const receivedPumpNumber = res.pump_number;
                    const pumpDirection = res.direction;
                    const pumpSpeed = res.speed;
                    const pumpPulses = res.pulses;
                    const pumpTimestamp = res.timestamp;
                                       
                    // Calculate the time difference from start time
                    const currentTime = Date.now();
                    const elapsedTime = currentTime -  startTime;
                
                    const newPumpStatus = { name: pumpName, pumpNo: receivedPumpNumber, direction: pumpDirection, 
                        speed: pumpSpeed, pulses: pumpPulses, timestamp: pumpTimestamp, elapsedTime: elapsedTime };

                    setPumpStatus(newPumpStatus); // Set only the latest data
                
                    setError(null); // Clear any previous errors if successful
                } else {
                    setError('Invalid response structure from API.');
                }
            } catch (err) {
                const errMsg = err.response?.data?.detail || 'Failed to send command for pump status';
                console.error(errMsg);
                setError(errMsg);
            }
        });
    }, [startTime, pumpName, pumpNumber, addCommandToQueue]);

    // Handle starting and stopping the periodic reading process
    useEffect(() => {
        let intervalId;
        if (isUpdating) {
            intervalId = setInterval(() => {
                readPumpStatus();
            }, updatePeriod);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [isUpdating, updatePeriod, readPumpStatus]);

    // Function to handle Start/Stop button click
    const handleStartStopUpdating = () => {
        setIsUpdating(prevUpdating => {
            const newUpdatingState = !prevUpdating; 
            if(newUpdatingState){
                setStartTime(Date.now());   // Capture the time when readings start
            }
            return newUpdatingState;
        });
    };

    // Function to handle slider change
    const handlePeriodChange = (event) => {
        const newPeriod = event.target.value;
        setUpdatePeriod(newPeriod);
    };

    return (
        <div className="pump-status border border-2 rounded p-3">
            <h5 className='mb-3'>Pump Status </h5>
            <div className='row'>
                <div className="col-10">
                    <div className="period-slider row mb-3">
                        <div className="col-2">
                            <label htmlFor="updatePeriodSlider" className="form-label">
                                Update Period (ms)
                            </label>
                        </div>
                        <div className="col-10">
                            <div className="d-flex align-items-center">
                                <input
                                    type="range"
                                    className="form-range range"
                                    id="updatePeriodSlider"
                                    min="100"
                                    max="5000"
                                    step="50"
                                    value={updatePeriod}
                                    onChange={handlePeriodChange}
                                    style={{ marginRight:'20px'}}
                                />
                                {/* Display readPeriod value inline */}
                                <span >{updatePeriod}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">     {/*  d-flex align-items-center justify-content-center*/}
                    <button
                        className={`btn ${isUpdating ? 'btn-danger' : 'btn-primary'}`}
                        onClick={handleStartStopUpdating}
                    >
                        {isUpdating ? 'Stop Updating' : 'Start Updating'}
                    </button>
                </div>
            </div>
            {error && <p className="text-danger">{error}</p>}
            {pumpStatus && (
                <div>
                    <p>Pump No: {pumpStatus.pumpNo}</p>
                    <p>Direction: {pumpStatus.direction}</p>
                    <p>Speed: {pumpStatus.speed}</p>
                    <p>Pulses: {pumpStatus.pulses}</p>
                    <p>Time since start: {pumpStatus.timestamp} ms</p>
                    <p>Elapsed Time: {pumpStatus.elapsedTime} ms</p>
                </div>
            )}
        </div>
    );
}
        
export default PumpStatus; 

import React, { useState, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";
import api from "../api"; // Make sure to define the API utility

function ValveControl({ valveName, valveNumber, addCommandToQueue }) {
    const [isOn, setIsOn] = useState(false);
    const [error, setError] = useState(null);

    // Load saved values from local storage on component mount
    useEffect(() => {
        const savedState = localStorage.getItem(`valve${valveNumber}State`);
        
        if (savedState !== null) setIsOn(JSON.parse(savedState));
    }, [valveNumber]);

    // Function to send the pump control command
    const debouncedSendCommand = useMemo(() => 
        debounce(async (valveState) => {
            addCommandToQueue(async () => {
                const command = {
                    valve_number: valveNumber,
                    state: valveState ? 1 : 0,  // 1 is on, 0 is off
                };
                console.log(`Sending request to ${valveName} with state ${valveState}`);
                try {
                    await api.post(`/valve/control_valve/`, command);
                    setError(null); // Clear any previous errors if successful
                } catch (err) {
                    console.error(`Failed to send command for ${valveName}:${err.response.data.detail}`);
                    setError(`${err.response.data.detail}`);
                }
            });
        }, 50),
        [valveNumber, addCommandToQueue, valveName]
    );

    const handleStateChange = () => {
        const newIsOn = !isOn;
        setIsOn(newIsOn);
        localStorage.setItem(`valve${valveNumber}State`, JSON.stringify(newIsOn)); // Save state to local storage
        debouncedSendCommand(newIsOn);  // Call the debounced function
    };

    return (
        <div className="valve-control">
            <h6>{valveName}</h6>
            {/* Valve Switch */}
            <div className="d-flex align-items-center justify-content-center">
                <span className="me-2">OFF</span>
                <div className="form-check form-switch aria-label">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`valve${valveNumber}Switch`}
                        checked={isOn}
                        onChange={(e) => {
                            handleStateChange();        
                            e.target.blur();          // Remove focus after the click
                        }}
                        style={{ minWidth: '100px', minHeight: '45px'}}
                    />
                </div>
                <span className="ms-2">ON</span>
            </div>
            <p className="text-danger" style={{ height: '2.0em', fontSize:'10px' }}>
                {error ? error : ''}
            </p>
        </div>
    );    
}
export default React.memo(ValveControl);
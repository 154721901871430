import './Testing.css';

import React, { useEffect, useState } from 'react';
import PumpControl from "../components/PumpControl";
import ValveControl from "../components/ValveControl";
import FlowMeterControl from '../components/FlowMeterControl';
import 'bootstrap/dist/css/bootstrap.min.css';
import CommandQueue from '../components/CommandQueue';
import PumpStatus from '../components/PumpStatus';
import { useLocation } from 'react-router-dom';
import screenSaverInstance from '../screensaver/ScreenSaver';


function Testing() {
    const [deviceAddress, setDeviceAddress] = useState(100); 
    const location = useLocation();
    const handleDeviceAddressChange = (event) => {
        setDeviceAddress(event.target.value);
    };

    useEffect(() => {
        screenSaverInstance.handleUserActivity(); // Inform ScreenSaver about user activity
    }, []); 

    useEffect(() => { 
        // Update original path 
        screenSaverInstance.setOriginalPath(location.pathname);
      }, [location.pathname]);

    return (
        <div className="container border border-3 rounded p-3 mt-3">
            <h1>I/O module</h1>
            {/* Input for device address */}
            <div className="mb-3">
                <label htmlFor="deviceAddress" className="form-label">Device Address</label>
                <input
                    type="number"
                    className="form-control"
                    id="deviceAddress"
                    value={deviceAddress}
                    onChange={handleDeviceAddressChange}
                    min="0"
                    step="1"
                />
            </div>
            <CommandQueue>
                {({ addCommandToQueue, pending }) => (
                <>
                    <div className="row align-items-center">
                        {/* Pumps (3/4 of the row) */}
                        <div className="col-9">
                            <div className="pump-control border border-2 rounded p-3">
                                {/* Pump Control Components */}
                                <PumpControl pumpName="PUMP 1" pumpNumber={0} addCommandToQueue={addCommandToQueue}/>
                                <PumpControl pumpName="PUMP 2" pumpNumber={1} addCommandToQueue={addCommandToQueue}/>
                                <PumpControl pumpName="PUMP 3" pumpNumber={2} addCommandToQueue={addCommandToQueue}/>
                                <PumpControl pumpName="PUMP 4" pumpNumber={3} addCommandToQueue={addCommandToQueue}/>
                            </div>
                        </div>
                        {/* Valve (1/4 of the row) */}
                        <div className="col-3">
                            <div className="valve-control border border-2 rounded p-3">
                                <ValveControl valveName="VALVE 1" valveNumber={0} addCommandToQueue={addCommandToQueue}/>
                                <ValveControl valveName="VALVE 2" valveNumber={1} addCommandToQueue={addCommandToQueue}/>
                                <ValveControl valveName="VALVE 3" valveNumber={2} addCommandToQueue={addCommandToQueue}/>
                                <ValveControl valveName="VALVE 4" valveNumber={3} addCommandToQueue={addCommandToQueue}/>
                                <ValveControl valveName="VALVE 5" valveNumber={4} addCommandToQueue={addCommandToQueue}/>
                            </div>
                        </div>
                    </div>
                    {/* Always render the placeholder */}
                    <p style={{ minHeight: '1.5em' }}>
                        {pending ? 'Processing commands... Please wait.' : ''}
                    </p>

                    <PumpStatus pumpName="PUMP 1" pumpNumber={0} addCommandToQueue={addCommandToQueue}/>

                    {/* Flow Meter Control */}
                    <FlowMeterControl flowmeterName="FM 1" flowmeterNumber={0} addCommandToQueue={addCommandToQueue}/>
                </>
                )}
            </CommandQueue>
        </div>
    );
}


export default Testing;
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import Testing from './pages/Testing';
import ScreenSaverVideo from './pages/ScreenSaverVideo';
import Select5 from './pages/Select5';
import screenSaverInstance from './screensaver/ScreenSaver';
import { useNavigate } from 'react-router-dom';


function App() {
  return(
    <Router>
      <Main />
    </Router>
  );
}


function Main() {

  const navigate = useNavigate();

  useEffect(() => {
    // Initialize the ScreenSaver and pass a navigation method if needed
    screenSaverInstance.initialize(navigate);
  }, [navigate]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Testing />} />
        <Route path="/screensaver" element={<ScreenSaverVideo />} />
        <Route path="/select5" element={<Select5 />} />
        <Route path="/select9" element={<Select5 />} />
        <Route path="/testing" element={<Testing />} />
      </Routes>
    </>
  );
}

export default App;

import './ScreenSaverVideo.css';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Video1 from '../assets/videos/screensaver1.mp4';
import screenSaverInstance from '../screensaver/ScreenSaver';
//import { useNavigate } from 'react-router-dom';

function ScreenSaverVideo() {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  //const navigate = useNavigate();

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  /*
  const handleVideoEnd = () => {
    navigate('/'); // Navigate to the home route when video ends
  };
  */
  

  // Set up event listeners for user activity
  useEffect(() => {
    const handleUserActivity = () => {
      console.log('User activity detected in video');
      screenSaverInstance.handleUserActivity();
    };

    // Add event listeners
    const events = ['mousemove', 'touchstart', 'touchmove'];
    events.forEach(event => window.addEventListener(event, handleUserActivity));

    // Cleanup function to remove event listeners
    return () => {
      events.forEach(event => window.removeEventListener(event, handleUserActivity));
    };
  }, []);

  return (
    <FullScreenContainer>
      <ReactPlayer
        url={Video1}
        playing={true} // Automatically play the video
        controls={false} // Hide controls
        loop={true} // Video will not loop
        muted={true} // Not muted
        playsinline={true} // Plays inline on mobile devices
        width="100vw"
        height="100vh"
        onReady={onLoadedData} // Set loaded state
        //onEnded={handleVideoEnd} // Handle video end
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: isVideoLoaded ? 1 : 0,
          transition: 'opacity 0.5s ease'
        }}
      />
    </FullScreenContainer>
  );
}

const FullScreenContainer = styled.div`
  width: 100vw; // Full width
  height: 100vh; // Full height
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; // Hide overflow
  position: relative; // Position relative for absolute children
  background-color: black; // Black background
`;

export default ScreenSaverVideo;
import { useState, useRef, useEffect } from 'react';

// Function to process commands
const processQueue = async (queueRef, setPending, setError) => {
    if (queueRef.current.length === 0) return;

    while (queueRef.current.length > 0) {
        const command = queueRef.current.shift();
        setPending(true);
        try {
            await command();
            setError(null);  // Clear any errors on success
        } catch (err) {
            if (err.response && err.response.data && err.response.data.detail) {
                // Assuming error message is in 'detail' field of response
                setError(`Failed to send command: ${err.response.data.detail}`);
            } else {
                // Fallback if no detailed error is provided by the API
                setError(`Failed to send command: An unknown error occurred.`);
            }
        } finally {
            setPending(false);  // Reset pending state
        }
    }
};

function CommandQueue({ children }) {
    const [pending, setPending] = useState(false);
    const [error, setError] = useState(null);
    const queueRef = useRef([]);

    const addCommandToQueue = (command) => {
        queueRef.current.push(command); // Add command to the queue
        processQueue(queueRef, setPending, setError); // Immediately process
    };

    useEffect(() => {
        if (!pending && queueRef.current.length > 0) {
            processQueue(queueRef, setPending, setError); // Process queue if not already processing
        }
    }, [pending]);

    return children({ addCommandToQueue, pending, error });
}

export default CommandQueue;
